















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Impressum extends Vue {
  content = {
    name: "Point Pioneers GmbH",
    fields: [
      {
        name: "Adresse",
        value: "Töpferstraße 12\n 67549 Worms",
      },
      {
        name: "Kontakt",
        value: "kontakt@point-pioneers.net",
      },
      {
        name: "Telefon",
        value: "+49 6241 / 85 44 627",
      },
      {
        name: "Geschäftsführer",
        value: "Hans Martin Steinbacher",
      },
      {
        name: "Handelsregister",
        value: "HRB 50918",
      },
      {
        name: "Umsatzsteuer-ID",
        value: "DE349850811",
      },
    ],
  };
}
