







































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Privacy extends Vue {
  private bulletList = [
    {
      title: "Company",
      text: '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Point Pioneers.',
    },
    {
      title: "Device",
      text: "means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
    },
    {
      title: "Personal Data",
      text: "is any information that relates to an identified or identifiable individual.",
    },
    {
      title: "Service",
      text: "refers to the advertisement, website, or application.",
    },
    {
      title: "Service Provider",
      text: "means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.",
    },
    {
      title: "Usage Data",
      text: "refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
    },
    {
      title: "You",
      text: "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
    },
  ];

  private dataProcessingList = [
    {
      title: "To provide and maintain our Service,",
      text: "including to monitor the usage of our Service.",
    },
    {
      title: "To manage Your Account:",
      text: "to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
    },
    {
      title: "For the performance of a contract:",
      text: "the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
    },
    {
      title: "To contact You:",
      text: "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    },
    {
      title: "To provide You with",
      text: "news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
    },
    {
      title: "To manage Your requests:",
      text: "To attend and manage Your requests to Us.",
    },
    {
      title: "For business transfers:",
      text: "We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
    },
    {
      title: "For other purposes:",
      text: "We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.",
    },
  ];

  private personalInfoList = [
    "First name and last name",
    "Email address",
    "Phone number",
    "Location details such as your address, city, or country",
    "Any other data such as personal preferences, requirements, or comments",
  ];

  private dataSharingList = [
    {
      title: "With Service Providers:",
      text: "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.",
    },
    {
      title: "For business transfers:",
      text: "We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
    },
    {
      title: "With Affiliates:",
      text: "We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
    },
    {
      title: "With business partners:",
      text: "We may share Your information with Our business partners to offer You certain products, services or promotions.",
    },
    {
      title: "With other users:",
      text: "When You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.",
    },
    {
      title: "With Your consent:",
      text: "We may disclose Your personal information for any other purpose with Your consent.",
    },
  ];
}
